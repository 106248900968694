//framework & plugin
import { MDBModal, MDBModalDialog, MDBModalContent } from 'mdb-react-ui-kit'
import { RemoveScroll } from 'react-remove-scroll'

export default function ModalBox({
  className,
  show,
  screenSize,
  content,
  setShow,
  scrollable,
  staticBackdrop,
  backButton,
  onDismiss,
  centered,
}) {
  return (
    <RemoveScroll>
      <MDBModal
        staticBackdrop={staticBackdrop}
        className={`full-modal-box-container ${className}`}
        tabIndex="-1"
        show={show}
        setShow={setShow}>
        {/* <div
          style={{ height: '100%', width: '100%', position: 'absolute' }}
          onClick={() => backButton()}></div> */}
        {onDismiss && (
          <div
            onClick={() => {
              if (onDismiss) {
                onDismiss()
              }
            }}
            style={{
              position: 'absolute',
              // background: 'red',
              width: '100%',
              height: '100%',
            }}></div>
        )}
        <MDBModalDialog
          centered={centered}
          size={`${screenSize}`}
          scrollable={scrollable ? true : false}>
          <MDBModalContent>{content}</MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </RemoveScroll>
  )
}
