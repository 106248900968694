import { Icon } from '@iconify/react'
// reducer
import { useDispatch, useSelector } from 'react-redux'
import { closeSignModal, getOrderHistory } from '../../redux/reducer/orderReducer'

// hook
import useWindowDimensions from '../common/window-dimension'

// component
import ModalBox from './full.modal.box'

import FileViewer from 'react-file-viewer'
import SignatureCanvas from 'react-signature-canvas'

// assets
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// Add libraries for PDF generation and manipulation
import jsPDF from 'jspdf'
import DOForm from '../pdf/doForm'
import { pdf } from '@react-pdf/renderer'
import { PDFDocument } from 'pdf-lib'
import { updateSignDoc } from '../../redux/reducer/orderReducer'
import { toast } from 'react-toastify'
import PdfWithSignature from '../pdf/component/render.pdf'
import { MDBModalBody } from 'mdb-react-ui-kit'

export default function SignModal({ show }) {
  const { height, width } = useWindowDimensions()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [fileBlob, setFileBlob] = useState(null)
  const [isSigned, setIsSigned] = useState(false)
  const [isCompleted, setIsCompleted] = useState(false)
  const [fileType, setFileType] = useState(false)
  const [showSignatureModal, setShowSignatureModal] = useState(false)
  // const [signatureData, setSignatureData] = useState(null)
  const [loading, setLoading] = useState(false) // Loading state
  const [submitting, setSubmitting] = useState(false) // Loading state

  const { file } = useSelector((state) => state.order)

  const sigCanvasRef = useRef(null)

  const onDialogClose = () => {
    console.log('Modal is closing')
    setFileBlob(null)
    setIsSigned(null)
    setShowSignatureModal(false)
    // setSignatureData(null)
    dispatch(closeSignModal())
    if (isCompleted) {
      dispatch(getOrderHistory())
    }
  }

  // const handleSaveSignature = () => {
  //   if (sigCanvasRef.current) {
  //     const signature = sigCanvasRef.current.toDataURL()
  //     setSignatureData(signature) // Save the signature
  //     setIsSigned(true)
  //     // setShowSignatureModal(false)
  //   }
  // }

  const handleSaveAndSubmitSignature = async () => {
    if (sigCanvasRef.current && isSigned) {
      const signature = sigCanvasRef.current.toDataURL() // Get signature as Data URL

      try {
        setSubmitting(true)
        let signedBlob
        let filename

        if (fileType === 'pdf') {
          // Process PDF
          const responseBlob = await fetch(fileBlob).then((res) => res.blob())
          const arrayBuffer = await responseBlob.arrayBuffer()

          // Embed the signature image
          const pdfDoc = await PDFDocument.load(arrayBuffer)

          // Embed the signature image
          const signatureImageBytes = await fetch(signature).then((res) => res.arrayBuffer())
          const signatureImage = await pdfDoc.embedPng(signatureImageBytes)

          const pages = pdfDoc.getPages()

          for (let page of pages) {
            const pageHeight = page.getHeight()
            const pageWidth = page.getWidth()

            // Calculate signature position and dimensions
            const { width, height } = signatureImage.scale(0.3) // Scale as needed
            let signatureX, signatureY

            if (file?.type == 'preview_from_pdf') {
              // Place signature at the bottom right of each page
              signatureX = pageWidth - width - 150 // 50px margin from the right
              signatureY = 150 // 50px margin from the bottom
            } else {
              // Fixed coordinates for other types
              signatureX = 360 // X-coordinate
              signatureY = pageHeight - 600 - height // Adjust as needed 620
            }

            if (file?.type == 'preview_from_pdf') {
              // Top-right placement
              page.drawImage(signatureImage, {
                x: pageWidth - width - 50, // 50px margin from the right
                y: pageHeight - 150, // 50px margin from the top
                width,
                height,
              })

              // Bottom-right placement
              page.drawImage(signatureImage, {
                x: pageWidth - width - 50, // 50px margin from the right
                y: 150, // 50px margin from the bottom
                width,
                height,
              })
            } else {
              // Original logic for other types
              const signatureX = 360 // X-coordinate
              const signatureY = pageHeight - 600 - height // Adjust as needed (620)
              page.drawImage(signatureImage, {
                x: signatureX,
                y: signatureY,
                width,
                height,
              })
            }
          }

          const signedPdfBytes = await pdfDoc.save()
          signedBlob = new Blob([signedPdfBytes], { type: 'application/pdf' })
          filename = file?.type == 'preview_from_pdf' ? 'signed_geran_roadtax.pdf' : 'signed_do.pdf'
        } else {
          // Process Image
          const img = new Image()
          img.src = fileBlob

          await new Promise((resolve) => {
            img.onload = resolve
          })

          const canvas = document.createElement('canvas')
          const ctx = canvas.getContext('2d')

          canvas.width = img.width
          canvas.height = img.height

          ctx.drawImage(img, 0, 0)

          const signatureImg = new Image()
          signatureImg.src = signature

          await new Promise((resolve) => {
            signatureImg.onload = resolve
          })

          const sigWidth = signatureImg.width * 0.3 // Scale signature
          const sigHeight = signatureImg.height * 0.3
          // const x = img.width - sigWidth - 50 // Bottom-right placement
          // const y = img.height - sigHeight - 50

          // ctx.drawImage(signatureImg, x, y, sigWidth, sigHeight)

          if (file?.type === 'preview_from_pdf') {
            // Top-right placement
            ctx.drawImage(
              signatureImg,
              img.width - sigWidth - 50, // 50px margin from the right
              img.height * 0.3 - sigHeight / 2, // 30% of the height, vertically centered
              sigWidth,
              sigHeight,
            )

            // Bottom-right placement
            ctx.drawImage(
              signatureImg,
              img.width - sigWidth - 50, // 50px margin from the right
              img.height * 0.7 - sigHeight / 2, // 70% of the height, vertically centered
              sigWidth,
              sigHeight,
            )
          } else {
            // Original logic for other types
            const x = img.width - sigWidth - 50 // Bottom-right placement
            const y = img.height - sigHeight - 50
            ctx.drawImage(signatureImg, x, y, sigWidth, sigHeight)
          }

          signedBlob = await new Promise((resolve) => canvas.toBlob(resolve, file?.type))
          // filename = `signed_image.${file?.type.split('/')[1]}` // Filename for image
          filename = `signed_image.${fileType}`

          filename =
            file?.type === 'preview_from_pdf'
              ? `signed_geran_roadtax.${fileType}`
              : `signed_do.${fileType}`
        }

        // Prepare FormData
        const formData = new FormData()
        const key =
          file?.type === 'preview_from_pdf'
            ? 'case_item_geran_roadtax_with_sign[0]'
            : 'case_item_do_with_sign[0]'

        formData.append(key, signedBlob, filename)
        formData.append('id', file?.id)
        formData.append('sign_from_id', file?.url?.id ?? null)

        // Dispatch the upload
        dispatch(updateSignDoc(formData))
          .unwrap()
          .then(async (res) => {
            setSubmitting(false)

            if (res.status !== 'success') {
              toast.error('Add signature unsuccessful')
            } else {
              setIsCompleted(true)
              setShowSignatureModal(false)
              toast.success('Add signature successfully')

              // Update fileBlob with the new signed file
              setFileBlob(null)
              const response = await fetch(res?.data?.api_url)
              const signedFileBlob = await response.blob()
              const fileUrl = URL.createObjectURL(signedFileBlob)

              setFileBlob(fileUrl)
            }
          })
          .catch(() => {
            setSubmitting(false)
            toast.error('An unexpected error occurred @contact support', {
              theme: 'colored',
              hideProgressBar: true,
              closeOnClick: true,
              closeButton: false,
            })
          })
      } catch (error) {
        setSubmitting(false)
        setShowSignatureModal(false)
        toast.error('Submission failed. Please try again.')
      }
    } else {
      setSubmitting(false) // Hide loading indicator
      toast.error('Please save your signature before submitting.')
    }
  }

  const handleClearSignature = () => {
    if (sigCanvasRef.current) {
      sigCanvasRef.current.clear() // Clear the signature
      setIsSigned(false)
    }
  }

  const generatePDF = async () => {
    try {
      setLoading(true) // Start loading
      const blob = await pdf(<DOForm data={file?.autoformData} />).toBlob() // Generate PDF blob
      setFileBlob(URL.createObjectURL(blob)) // Set the generated blob as file URL
      setLoading(false) // End loading
    } catch (error) {
      console.error('Error generating PDF:', error)
      setLoading(false) // End loading on error
    }
  }

  useEffect(() => {
    const fetchPDF = async () => {
      try {
        setLoading(true)
        const response = await fetch(file?.url?.api_url)
        if (!response.ok) throw new Error('Failed to fetch PDF')
        const blob = await response.blob()
        setFileBlob(URL.createObjectURL(blob)) // Set the fetched blob as file URL
        setLoading(false) // End loading
      } catch (error) {
        console.error('Error fetching PDF:', error)
        setLoading(false)
      }
    }

    if (file?.type === 'preview_from_pdf' && file?.url?.api_url) {
      const fileExtension = file?.url?.api_url.split('.').pop()
      setFileType(fileExtension)

      fetchPDF() //completed
    } else if (file?.type === 'generate_from_data') {
      setFileType('pdf')
      generatePDF()
    }
  }, [file?.type, file?.id])

  const handleDownload = (fileBlob, filename = 'file.pdf') => {
    const link = document.createElement('a')
    link.href = fileBlob
    link.download = filename
    link.click()
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        onDialogClose()
      }
    }
    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [])

  return (
    <ModalBox
      className="sign-modal-container"
      show={show}
      centered={true}
      onDismiss={onDialogClose}
      backButton={onDialogClose}
      desktopModal={width >= 991 ? true : false}
      screenSize={width >= 991 ? 'xl' : 'fullscreen-md-down'}
      // screenSize="fullscreen-md-down"
      content={
        <MDBModalBody>
          <div className="d-flex justify-content-between align-items-center mb-4">
            <div style={{ display: 'flex' }}>
              <h3>{file?.url?.display_name}</h3>
            </div>
            <div className="text-end" style={{ marginRight: '-0.8em' }}>
              <Icon
                onClick={() => onDialogClose()}
                className="icon-close-btn"
                icon="clarity:window-close-line"
                width={30}
                height={30}
              />
            </div>
          </div>
          {!loading && fileBlob !== null ? (
            <>
              <div>
                <div className="file-frame" style={{ maxHeight: showSignatureModal ? '22em' : '' }}>
                  <div className="file-content">
                    {fileType === 'pdf' ? (
                      <>
                        <FileViewer key={file?.id} fileType={fileType} filePath={fileBlob} />
                        {/* <div
                          style={{
                            position: 'absolute',
                            right: '5em',
                            top: 0,
                          }}>
                          <span
                            style={{
                              position: 'fixed',
                              cursor: 'pointer',
                              backgroundColor: '#f0f0f0', // Light grey background
                              borderRadius: '50%', // Makes the background a circle
                              display: 'flex', // Center the icon
                              alignItems: 'center',
                              justifyContent: 'center',
                              width: '45px', // Diameter of the circle
                              height: '45px',
                              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                            }}>
                            <Icon
                              onClick={() => window.open(fileBlob, '_blank')}
                              icon="bi:arrows-fullscreen"
                              width={25}
                              height={25}
                              style={{ color: 'black' }}
                            />
                          </span>
                        </div> */}
                      </>
                    ) : fileType === 'jpeg' || fileType === 'jpg' || fileType === 'png' ? (
                      <>
                        <img src={fileBlob} alt="Preview" style={{ width: '100%' }} />
                        {/* <span
                          style={{
                            position: 'absolute',
                            bottom: '10px',
                            right: '10px',
                            cursor: 'pointer',
                            backgroundColor: '#f0f0f0', // Light grey background
                            borderRadius: '50%', // Makes the background a circle
                            display: 'flex', // Center the icon
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '45px', // Diameter of the circle
                            height: '45px',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Optional shadow for a better look
                          }}>
                          <Icon
                            onClick={() => window.open(fileBlob, '_blank')}
                            icon="bi:arrows-fullscreen"
                            width={25}
                            height={25}
                            style={{ color: 'black' }}
                          />
                        </span> */}
                      </>
                    ) : (
                      <div>Unsupported file type</div>
                    )}
                    {/* <FileViewer key={file?.id} fileType={fileType} filePath={fileBlob} /> */}
                  </div>
                  {/* <PdfWithSignature pdfBlob={fileBlob} signatureData={signatureData} /> */}
                </div>
                {/* {signatureData && (
                  <img
                    src={signatureData}
                    alt="Signature"
                    style={{
                      position: 'absolute',
                      top: '490px', // sign placement
                      left: '270px',
                      width: '100px',
                    }}
                  />
                )} */}
              </div>
              {showSignatureModal && (
                <div className="signature-container">
                  <div className="text-end" style={{ marginRight: '-0.8em' }}>
                    <Icon
                      onClick={() => {
                        setShowSignatureModal(false) // Close the modal
                        handleClearSignature() // Clear the signature canvas
                      }}
                      className="icon-close-btn"
                      icon="flowbite:close-circle-solid"
                      width={25}
                      height={25}
                    />
                  </div>
                  <SignatureCanvas
                    ref={sigCanvasRef}
                    canvasProps={{
                      width: width < 550 ? 300 : 450,
                      height: 200,
                      // width: '100%',
                      // height: '100%',
                      style: { border: '1px solid black', background: 'white' },
                    }}
                    onEnd={() => setIsSigned(true)}
                  />
                  {/* <div className="button-container">
                    <button
                      className="save-button"
                      onClick={handleSaveAndSubmitSignature}
                      disabled={loading}>
                      Save and Submit
                    </button>
                    <button className="clear-button" onClick={handleClearSignature}>
                      Clear
                    </button>
                  </div> */}
                </div>
              )}
              <div className="d-flex mt-4 gap-3">
                <button
                  className="add-signature-button"
                  disabled={submitting || file?.status === 'Completed' || isCompleted}
                  onClick={() =>
                    showSignatureModal
                      ? handleSaveAndSubmitSignature()
                      : setShowSignatureModal(true)
                  }>
                  {showSignatureModal
                    ? 'Save and Submit'
                    : file?.status === 'Completed'
                    ? 'Signature Added'
                    : 'Add Signature'}
                </button>
                {showSignatureModal && (
                  <button className="clear-button" onClick={handleClearSignature}>
                    Clear
                  </button>
                )}
              </div>
              <div className="d-flex mt-4 gap-3">
                {!showSignatureModal && (
                  <button
                    className="add-signature-button"
                    onClick={() =>
                      handleDownload(
                        fileBlob,
                        file?.url?.display_name ? file?.url?.display_name : 'delivery_order.pdf',
                      )
                    }>
                    Download
                    {/* <Icon
                      icon="ic:baseline-download"
                      width={35}
                      height={30}
                      style={{ color: 'black', marginLeft: '10px' }}
                    /> */}
                  </button>
                )}
              </div>
            </>
          ) : (
            <p>Loading...</p>
          )}
          {/* Modal for Signature Canvas
          <ModalBox
            className="signature-modal"
            show={showSignatureModal}
            staticBackdrop={true}
            onDismiss={() => setShowSignatureModal(false)}
            content={
              <div className="signature-container">
                <SignatureCanvas
                  ref={sigCanvasRef}
                  canvasProps={{
                    width: 450,
                    height: 200,
                    // width: '100%',
                    // height: '100%',
                    style: { border: '1px solid black', background: 'white' },
                  }}
                />
                <div className="button-container">
                  <button
                    className="save-button"
                    onClick={handleSaveAndSubmitSignature}
                    disabled={loading}>
                    Save and Submit
                  </button>
                  <button className="clear-button" onClick={handleClearSignature}>
                    Clear
                  </button>
                </div>
              </div>
            }
          /> */}
        </MDBModalBody>
      }
    />
  )
}
