import { Field, useFormikContext } from 'formik'
import { MDBRow } from 'mdb-react-ui-kit'
import { useEffect, useState } from 'react'

export default function Text({
  label,
  name,
  disabled,
  placeholder,
  verification,
  className,
  autocomplete,
  customValue,
  handleOnCustomValueChange,
  suffix,
  prefix,
}) {
  const { errors, setFieldValue } = useFormikContext()
  const [countdown, setCountdown] = useState(null)
  const [timerId, setTimerId] = useState(null)
  // const [isReadOnly, setIsReadOnly] = useState(true)

  useEffect(() => {
    if (countdown === 0) {
      // 清除计时器
      clearInterval(timerId)
      setCountdown(null)
    }
  }, [countdown, timerId])

  const handleClick = () => {
    if (countdown === null) {
      // 开始倒计时
      setCountdown(60)
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1)
      }, 1000)
      setTimerId(timer)
    }
  }
  const formatTimer = (time) => {
    if (time === 60) {
      return time + ':00'
    } else {
      return '00:' + time
    }
  }

  return (
    <div className="form-input">
      {label && (
        <MDBRow>
          <label
            className="form-label"
            style={className ? { color: '#D1D5DB' } : { color: '#24262D' }}>
            {label}
          </label>
        </MDBRow>
      )}
      <MDBRow className={`form-group _text ${errors[name] ? '--errors' : ''}`}>
        {/* {customValue === '' ? (
          <Field
            type="text"
            name={name}
            placeholder={placeholder}
            disabled={disabled}
            className={className ? className : ''}
            autoComplete={autocomplete}
            value={customValue}
            onChange={(e) => {
              handleOnCustomValueChange(e.target.value)
              setFieldValue(name, e.target.value)
            }}
            // readOnly={isReadOnly}
            // onFocus={handleOnFocus()}
          />
        ) : (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Field
              type="text"
              name={name}
              placeholder={placeholder}
              disabled={disabled}
              className={className ? className : ''}
              autoComplete={autocomplete}
            />
            {suffix && <div style={{ marginLeft: '18px' }}>{suffix}</div>}
          </div>
        )} */}

        <div style={{ display: 'flex', alignItems: 'center' }}>
          {/* {prefix && <div style={{ marginLeft: '4px' }}>{prefix}</div>} */}
          {prefix && (
            <div
              className={className ? className : ''}
              style={{
                padding: '8px 0px 8px 12px',
                // backgroundColor: '#0B0D13', // Match input background
                // border: '0.15em solid #667080',
                // borderBottom: 'none',
                // borderTop: 'none',
                // borderRight: 'none',
                // borderLeft: 'none',
                borderRadius: '4px 0 0 4px', // Rounded corners for the left side
                color: '#D1D5DB', // Text color matching the input
                fontSize: '18px', // Match font size
                display: 'flex',
                alignItems: 'center',
              }}>
              {prefix}
            </div>
          )}
          <Field
            type="text"
            name={name}
            placeholder={placeholder}
            disabled={disabled}
            className={className ? className : ''}
            autoComplete={autocomplete}
            value={customValue}
            onChange={(e) => {
              if (customValue === '') {
                handleOnCustomValueChange(e.target.value)
              }
              setFieldValue(name, e.target.value)
            }}
          />
          {suffix && <div style={{ marginLeft: '18px', marginBottom: '-12px' }}>{suffix}</div>}
        </div>

        {verification && (
          <div className="verification-button">
            <button type="button" onClick={() => handleClick()}>
              {countdown === null ? 'SEND' : `SEND ${formatTimer(countdown)}`}
            </button>
          </div>
        )}
      </MDBRow>
      {errors && errors[name] ? (
        <div className="text-right no-padding error-message">{errors[name]}</div>
      ) : null}
    </div>
  )
}
