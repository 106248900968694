import { useRef, useState } from 'react'
import JSZip from 'jszip'
import jsPDF from 'jspdf'

//hook
import useWindowDimensions from '../../../components/common/window-dimension'

//lib
import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import { Icon } from '@iconify/react'
import { openSignModal, setFile } from '../../../redux/reducer/orderReducer'
import { useDispatch, useSelector } from 'react-redux'
import SignModal from '../../../components/modal/pdf.sign.modal'

export default function OrderCard({ data }) {
  const dispatch = useDispatch()
  const { width } = useWindowDimensions()
  const [active, setActive] = useState(false)
  // const [file, setFile] = useState('')
  // const [type, setType] = useState('')
  // const [status, setStatus] = useState('')
  // const [id, setId] = useState('')
  // const [autoFormData, setAutoformData] = useState('')
  const { isOpenModal } = useSelector((state) => state.order)
  const [loader, setLoader] = useState(false)

  const handleDownloadAll = async (event, urlList, name) => {
    setLoader(true)
    event.preventDefault()
    event.stopPropagation()

    if (urlList && urlList.length > 0) {
      try {
        if (urlList.length === 1) {
          const url = urlList[0].api_url
          const response = await fetch(url)
          if (!response.ok) {
            throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`)
          }
          const blob = await response.blob()
          const fileExtension = url.split('.').pop()
          const singleFileUrl = window.URL.createObjectURL(blob)
          const link = document.createElement('a')

          link.href = singleFileUrl
          link.setAttribute('download', `${urlList[0].display_name ?? name}.${fileExtension}`)

          document.body.appendChild(link)
          link.click()

          document.body.removeChild(link)
        } else {
          const zip = new JSZip()

          const fetchPromises = urlList.map((url, index) => {
            return fetch(url.api_url)
              .then((response) => {
                if (!response.ok) {
                  throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`)
                }
                return response.blob()
              })
              .then((blob) => {
                const fileExtension = url.api_url.split('.').pop()
                zip.file(`${url.display_name}.${fileExtension}`, blob)
              })
              .catch((error) => console.error(`Error downloading image: ${error.message}`))
          })

          await Promise.all(fetchPromises)

          const zipBlob = await zip.generateAsync({ type: 'blob' })
          const zipUrl = window.URL.createObjectURL(zipBlob)
          const link = document.createElement('a')

          link.href = zipUrl
          link.setAttribute('download', `${name}.zip`)

          document.body.appendChild(link)
          link.click()

          document.body.removeChild(link)
        }
        setLoader(false)
      } catch (error) {
        setLoader(false)
        console.error('Error exporting:', error)
      }
    }
  }

  const handleOpen = (event, fileUrl, file_name, id, status) => {
    event.preventDefault()
    event.stopPropagation()

    if (file_name == 'DELIVERY_ORDER') {
      if (status === 'Completed') {
        dispatch(
          setFile({
            id: id,
            status: status,
            url: fileUrl[0],
            type: 'preview_from_pdf',
          }),
        )
      } else {
        dispatch(
          setFile({
            id: id,
            status: status,
            type: 'generate_from_data',
            autoformData: fileUrl[0],
          }),
        )
      }
    } else {
      dispatch(
        setFile({
          id: id,
          status: status,
          url: fileUrl[0],
          type: 'preview_from_pdf',
        }),
      )
    }
    dispatch(openSignModal())
  }

  const containerHeight = width >= 991 ? 4.5 : 5.3
  // const topContainerHeight = 7
  // const dataLength =
  //     data.task?.length !== 1
  //     ? data.task.length * 3 + containerHeight + topContainerHeight
  //     : 5 + containerHeight + topContainerHeight

  return (
    <>
      {isOpenModal && (
        <SignModal
          show={isOpenModal}
          // file={file}
          // data={autoFormData}
          // type={type}
          // id={id}
          // status={status}
        />
      )}
      <MDBContainer
        style={{
          height:
            active && data.status === 'Pending' ? '14em' : active ? 'auto' : `${containerHeight}em`,
        }}
        className={
          active ? `order-card-container --active ` : `order-card-container --${data.status}`
        }
        onClick={() =>
          data.status === '-' ? '' : data.status !== 'complete' ? setActive(!active) : ''
        }>
        <label className="_label --white-text-huge">SALES ORDER : {data.sales_order}</label>
        <label className="_label --white-text-big">
          Status : <span className="_label --gold-text">{data.status}</span>
        </label>
        {data.status !== 'complete' && (
          <MDBRow className="padding_column">
            <MDBCol>
              <label className="_label --white-text-medium">FROM :</label>
              <MDBRow>
                <label className="_label --grey-text-small">
                  {/* ({data.from['registration number']}) */}({data.from?.sales_agent_contact})
                </label>
              </MDBRow>
              <MDBRow className="">
                <label className="_label --white-text-medium">{data.from?.sales_agent_name}</label>
              </MDBRow>
            </MDBCol>
            <MDBCol className="text-left">
              <label className="_label --white-text-medium">TO : </label>
              <MDBRow>
                <label>
                  <span className="_label --grey-text-medium">Name :</span>
                  <span className="_label --white-text-medium">
                    &nbsp;{data.to?.name ? data.to.name : '-'}
                  </span>
                </label>
              </MDBRow>
              <MDBRow>
                <label>
                  <span className="_label --grey-text-medium">Contact :</span>
                  <span className="_label --white-text-medium">&nbsp;{data.to?.contact}</span>
                </label>
              </MDBRow>
            </MDBCol>
            {data.status !== 'Pending' ? <div className="line-container --gold-small"></div> : ''}
          </MDBRow>
        )}
        <div>
          {data.status === 'Pending'
            ? ''
            : data?.task?.map((item, index) => (
                <div key={index} className="padding_column_small">
                  {item?.description === 'BOL ' ? (
                    <MDBRow style={{ height: '3em' }}>
                      <MDBCol className="col-3 col-md-2 date_column">
                        <label>{item.completeDate ? item.completeDate : '-'}</label>
                      </MDBCol>
                      <MDBCol className="col-1 text-center dot_column">
                        <label className="line-container --dot"></label>
                      </MDBCol>
                      <MDBCol>
                        <label className="text-start">Car Success Order!&nbsp;&nbsp;&nbsp;</label>
                      </MDBCol>
                    </MDBRow>
                  ) : (
                    ''
                  )}
                  <MDBRow>
                    <MDBCol className="col-3 col-md-2 date_column">
                      <label>{item.completeDate ? item.completeDate : '-'}</label>
                    </MDBCol>
                    <MDBCol className="col-1 text-center dot_column">
                      <label className="line-container --dot"></label>
                    </MDBCol>
                    <MDBCol>
                      <label className="text-start">
                        {item.description}&nbsp;&nbsp;&nbsp;
                        <span>{item.status === 'Completed' ? item.status : ''}</span>
                      </label>
                    </MDBCol>
                    <MDBCol className="text-center col-2">
                      {item.fileUrl?.length > 0 ? (
                        <>
                          <a
                            style={{ color: 'white' }}
                            // href={`${item?.fileUrl}`}
                            onClick={(event) => {
                              // setActive(active)
                              const file_name =
                                item.description == 'Insurance'
                                  ? 'insurance_cover'
                                  : item.description == 'JPJ Doc'
                                  ? 'JPJ_DOC'
                                  : item.description == 'Delivery Order'
                                  ? 'DELIVERY_ORDER'
                                  : 'DOCUMENT_FILE'

                              if (!loader) {
                                if (file_name == 'insurance_cover') {
                                  // handleDownloadAll(event, item?.fileUrl, file_name)
                                  handleDownloadAll(event, item?.fileUrl, file_name)
                                } else {
                                  handleOpen(
                                    event,
                                    item?.fileUrl,
                                    file_name,
                                    item?.sales_order_id,
                                    item.status,
                                  )
                                }
                              }
                            }}
                            download>
                            <Icon
                              icon={
                                loader
                                  ? 'line-md:loading-alt-loop'
                                  : item.description == 'Insurance'
                                  ? 'material-symbols:sim-card-download-sharp'
                                  : item.status == 'Completed'
                                  ? 'carbon:document-tasks'
                                  : 'carbon:document-blank'
                              }
                              width="23"
                              height="23"
                            />
                          </a>
                        </>
                      ) : (
                        ''
                      )}
                    </MDBCol>
                  </MDBRow>
                </div>
              ))}
        </div>
        {data.status !== 'complete' ? <div className="line-container --short-line"></div> : ''}
      </MDBContainer>
    </>
  )
}
