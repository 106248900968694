import { View, Text, StyleSheet, Font, Image } from '@react-pdf/renderer'
import Table from '../component/table'
import TickIcon from '../../../assets/images/pdf/tick_icon_do.png'

Font.register({
  family: 'Times',
  src: require('../fonts/times.ttf'),
})
Font.register({
  family: 'Seguisym',
  src: require('../fonts/seguisym.ttf'),
})

const styles = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    // border: "1px solid black",
    // borderBottom: "none",
    // borderTop: "none",
  },
  fiveColumnBorder: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    border: '1px solid black',
  },
  fiveColumnText: {
    alignSelf: 'center',
    fontSize: 8.76,
    color: 'black',
    // fontFamily: "Times",
  },
  symbolText: {
    alignSelf: 'center',
    fontSize: 7.39,
    color: 'black',
    // fontFamily: "Seguisym",
  },
})
const DoTable = ({ data }) => {
  const items = [
    { col2: 'SPARE KEY', key: 'spare_key' },
    { col2: 'FLOOR MAT', key: 'floor_mat' },
    { col2: 'TOWING COVER', key: 'towing_cover' },
    { col2: 'ANTENA (CONTINENTAL )', key: 'antena' },
    { col2: 'CIGARETTE LIGHTER', key: 'ciga_retta_lighter' },
    { col2: 'DARK GLASS', key: 'dark_glass' },
    { col2: 'SPARE TYRE', key: 'spare_tyre' },
    { col2: 'TOOL KITS', key: 'tool_kits' },
    { col2: 'JACK', key: 'jack' },
    { col2: 'CREDIT NOTE', key: 'credit_note' },
    { col2: 'WARRANTY BOOKLET', key: 'warranty_booklet' },
    { col2: 'COPY DOCUMENT OF PK SET', key: 'copy_document' },
    { col2: 'INSURANCE COVER NOTE', key: 'insurance_cover' },
    { col2: 'ORIGINAL ROAD TAX', key: 'original_road_tax' },
    { col2: 'ORIGINAL REGISTRATION CARD', key: 'original_registration_card' },
    { col2: 'TYRE REPAIR KIT', key: 'tyre_repair_kit' },
  ]

  const renderTableRows = () => {
    const filledRows = items
      .filter((item) => data?.do?.[item.key] === 'yes')
      .map((item, index) => (
        <Table
          key={item.key}
          col1={index + 1}
          col2={item.col2}
          col3={<Image src={TickIcon} />}
          col4={null}
        />
      ))

    const emptyRows = Array(16 - filledRows.length)
      .fill(null)
      .map((_, index) => (
        <Table
          key={`empty-${index}`}
          col1={filledRows.length + index + 1}
          col2={null}
          col3={null}
          col4={null}
        />
      ))

    return [...filledRows, ...emptyRows]
  }

  return (
    <div>
      <View style={styles.row}>
        <View style={{ flex: 7, borderBottom: '1px solid black' }}>
          <Table
            col1="No"
            col2="ITEM LIST (Whichever applicable)"
            col3={<Text style={styles.symbolText}>YES</Text>}
            col4={<Text style={styles.symbolText}>No</Text>}
          />
          {renderTableRows()}
        </View>
        <View style={{ flex: 5 }}>
          <View
            style={{
              borderRight: '1px solid black',
              borderTop: '1px solid black',
              borderBottom: '1px solid black',
              padding: 1,
            }}>
            <Text style={styles.fiveColumnText}>Remark</Text>
          </View>
          <View
            style={{
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              height: 202,
            }}>
            <Text style={styles.fiveColumnText}>{data?.do?.remark}</Text>
          </View>
        </View>
      </View>
    </div>
  )
}

export default DoTable
